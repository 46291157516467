import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('../components/AppFrame.vue')
  },
  {
    path: '/application',
    name: 'application',
    component: () => import('../components/AppFrame.vue')
  },
  {
    path: '/microsoftcallback',
    name: 'microsoftcallback',
    component: () => import('../components/MicrosoftCallback.vue')
  }
]

const router = new VueRouter({
  base: process.env.BASE_URL,
  routes
})

export default router
