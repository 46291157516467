<template>
  <div id="app">
    <v-app id="inspire">
      <div class="full-appbar">
        <div class="left-appbar"></div>
        <div class="right-appbar">
          <v-app-bar app dense color="#fff" flat>
            <v-toolbar-items>
              <v-icon @click="logout"> mdi-logout </v-icon>
            </v-toolbar-items>
          </v-app-bar>
        </div>
      </div>
      <v-main>
        <router-view />
      </v-main>
    </v-app>
  </div>
</template>

<script>
import json from "../public/config.json";

export default {
  data: () => ({ appName: "Vietmap Internal App", email: "", config: json }),
  mounted() {
    setTimeout(() => {
      let phoneNumber = localStorage.getItem("PhoneNumber");
      console.log("phoneNumer From App.Vue", phoneNumber);
      if (phoneNumber == null) {
        location.href =
          "https://personal-4u46wuro.outsystemscloud.com/Heineken/LoginByRedirect";
      }
    }, 3000);
  },
  methods: {
    logout() {
      localStorage.removeItem("PhoneNumber");
      //location.reload();
      location.href =
        "https://personal-4u46wuro.outsystemscloud.com/Heineken/LoginByRedirect";
    },
  },
};
</script>
<style scoped>
.full-appbar {
  width: 100%;
  height: 56px;
  position: absolute;
}
.left-appbar {
  width: 50%;
  float: left;
}
.right-appbar {
  width: 50%;
  min-width: 100px !important;
  float: right;
}

header {
  left: auto !important;
  right: 0;
}
.v-btn {
  color: #2c2c2c !important;
}
.branding {
  position: absolute;
  right: 12px;
  bottom: 12px;
  background: #fff;
  padding: 8px 24px 0 28px !important;
  z-index: 1000;
  border-radius: 8px;
  /* border: 1px solid; */
}

.v-toolbar__content {
  height: 56px !important;
}
</style>
